<template>
  <div>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
       <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , indexVal) in values" :key="indexVal">{{value}}</li>
        </ul>
      </div>
    </b-alert>
    <validation-observer ref="simpleRules">
      <b-card title="Add Translation">
      <b-row>
         <b-col cols="12">
              <b-row>
                <b-col md="1">
                  <div class="d-flex justify-content-center align-items-center">
                    <b-img fluid :src="data.cover"></b-img>
                  </div>
                </b-col>
                <b-col class="my-auto" md="9">
                  <h4>
                    <span style="color: #7367f0">name :</span>
                    {{ name }}
                  </h4>
                  <br />
                  <h4>
                    <span style="color: #7367f0">SKU رمز : </span>
                    {{ data.sku }}
                  </h4>
                  <br />
                </b-col>
              </b-row>
            </b-col>
<b-col cols="12">
        
          <b-form>
            <b-row>
              <b-col sm="12">
                <div>
                  <!-- Row Loop -->
                  <b-row
                    v-for="(item, index) in itemData.translations"
                    :id="item.id"
                    :key="item.id"
                    ref="row"
                  >
                    <!-- locale Reapeted -->
                    <b-col md="4">
                      <validation-provider
                        #default="{ errors }"
                        name="language"
                        rules="required"
                      >
                        <b-form-group
                          label="Language"
                          label-for="language"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            id="language"
                            v-model="transSelect[index]"
                            :state="transSelect[index] === null ? false : true"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="localeOptions"
                            :selectable="
                              (option) => !option.value.includes('select_value')
                            "
                            label="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="4">
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-group label="Name" label-for="name">
                          <b-form-input
                            id="TranslationName"
                            v-model="itemData.translations[index].name"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="4">
                      <b-form-group
                        label="Short Description"
                        label-for="short-description"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="short-description"
                          rules="required"
                        >
                          <b-form-input
                            id="short-description"
                            v-model="
                              itemData.translations[index].short_description
                            "
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Description" label-for="description">
                        <validation-provider
                          #default="{ errors }"
                          name="description"
                          rules="required"
                        >
                          <b-form-input
                            id="translationDescription"
                            v-model="itemData.translations[index].description"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col lg="2" md="3" class="mb-50">
                      <b-button
                        v-if="index > 0"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="translationsremoveItem(index)"
                      >
                        <feather-icon icon="XIcon" class="mr-25" />
                        <span>Remove</span>
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr />
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <!-- add new button -->
              <b-col sm="3">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  block
                  @click="translationsrepeateAgain"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>Add New</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
              <!-- submit button -->
              <b-col>
                <b-button
                  size="lg"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        
      </b-col>
      </b-row>
      </b-card>
      
    </validation-observer>
  </div>
</template>

<script>

import { heightTransition } from '@core/mixins/ui/transition'

import BCardCode from '@core/components/b-card-code'
// import axios from '@/libs/axios'
import vSelect from 'vue-select'
import { required } from '@validations'
export default {
  components: {
    BCardCode,
    vSelect,
  },
  
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      id: 0,
      itemData: {
        translations: [
          {
            locale: '',
            name: '',
            short_description: '',
            description: '',
          },
        ],
      },
      localeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      data: null,
      name: "",
      transSelect: [null],
      required,
    }
  },
  async created() {
    this.id = this.$route.params.id
    await this.getProduct() 
  },
  methods: {
    async getProduct() {
      await axios
        .get('products/' + this.id)
        .then((result) => {
          this.data = result.data.data

          this.name = this.data.translation[0].name
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          for (let trans in this.itemData.translations) {
            this.itemData.translations[trans].locale = this.transSelect[
              trans
            ].value
          }

          axios
            .post('products/' + this.id + '/translations/store', this.itemData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    //REPETED FORM
    translationsrepeateAgain() {
      this.itemData.translations.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.transSelect.push()
    },
    translationsremoveItem(index) {
      if (this.itemData.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.itemData.translations.splice(index, 1)
        this.transSelect.splice(index, 1)
      }
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
<style lang="scss">
 
.repeater-form {
  transition: 0.35s height;
}
#vs7__listbox,
.vs__dropdown-menu,
.vs__selected-options {
  z-index: 99999;
  position: absolute;
}
</style>
